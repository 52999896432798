<template>
  <section
    id="core-image"
  >
    <v-img
      :src="require('@/assets/core-image.jpg')"
      height="700"
      contain="true"
    />
  </section>
</template>

<script>
  export default {
    name: 'SectionCoreImage',
  }
</script>
